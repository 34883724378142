.App {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  overflow: hidden;
}


.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

::-webkit-scrollbar-thumb {
  background-color: #888;
}

::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}



.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}




@media only screen and (min-width: 521px) {
  body {
    background-image: url('./assets//desktop-bg.svg');
    background-repeat: no-repeat;
    background-size: cover;
    height: 100vh;
    background-position: center center;
  }
}

@media only screen and (min-width: 320px) {
  .App {
    align-items: flex-start;
    justify-content: flex-start;
    overflow: hidden;
    height: 100vw;
    max-height: 1000px;
    margin: 0 auto;
  }
}

@media only screen and (min-width: 768px) {
  .App {
    align-items: flex-start;
    justify-content: flex-start;
    width: 100vw;
    align-items: center;
    max-width: 320px;
    overflow: hidden;
    height: 100%;
    max-height: 700px;
    margin: 0 auto;
    background-color: transparent;
    top: 50%;
    left: 50%;
  }
}